import { types } from "../../types/types"

const initialState = {
  registros: [],
  endPoint: null
}

const list = (state = initialState, action) => {
  switch (action.type) {
    case types.load:
      // If server returns an error, you can handle it here
      if (!action.payload.data) action.payload.data = []
      return {
        ...state,
        endPoint: action.payload.endPoint,
        registros: [...action.payload.data]
      }
    case types.delete:
      return {
        ...state,
        registros: state.registros.filter(reg => reg.id !== action.payload)
      }
    case types.deleteRepair:
      state.registros[action.payload.index].repairs = state.registros[action.payload.index].repairs.filter(reg => reg.id !== action.payload.id)

      return {
        ...state,
        registros: [...state.registros]
      }
    case types.cleaningAll:
      return {
        ...state,
        endPoint: null,
        registros: []
      }
    case types.change:
      const { index, name, valor } = action.payload

      const newReg = {
        ...state.registros[index],
        [name]: valor
      }

      return {
        ...state,
        registros: state.registros.map((reg, i) => (
          i === index ? newReg : reg
        ))
      }

    case types.halfLoad:
      return {
        ...state,
        registros:
          state.registros.map((reg) => {
            return reg.id === action.payload.parentId ? { ...reg, details: action.payload.data } : reg
          })

      }

    default:
      return state
  }
}

export default list