

export const formTypes = {
    setFormData: '[FormReducer] Set Form Data',
    inputChange: '[FormReducer] Input changed',
    initForm: '[FormReducer] Init Form structure',
    initNestedForm: '[FormReducer] Init Nested Form structure',
    addRepeaterRegister: '[FormReducer] Add register for repeater',
    removeRepeaterRegister: '[FormReducer] Remove register for repeater',
    editRepeaterRegister: '[FormReducer] Edit register for repeater',
    cleanForm: '[FormReducer] Clean Values',
    fillFormData: '[FormReducer] Fill form data',
    SwitchPermission: '[Permissions] Switch permission',
    SwitchPermissionAll: '[Permissions] Switch all permission',
    FillSelect: '[Permissions] Fill select',
    editPickinRepeaterRegister: '[FormReducer] Edit register for pickin',
    removePickinRepeaterRegister: '[FormReducer] Remove register for pickin'
}