import { formTypes } from "../../types/formReducer/types"

// ** Initial State
const initialState = {}

const formReducer = (state = initialState, action) => {
    switch (action.type) {
        case formTypes.setFormData:
            return {
                ...action.payload
            }
        case formTypes.inputChange:
            return {
                ...state,
                [action.payload.name]: action.payload.value
            }

        case formTypes.initForm:
            return {
                ...action.payload
            }

        case formTypes.initNestedForm:
            return {
                ...state,
                ...action.payload
            }

        case formTypes.addRepeaterRegister:
            return {
                ...state,
                [action.payload.key]: [
                    ...state[action.payload.key],
                    { ...action.payload.structure }
                ]
            }

        case formTypes.removeRepeaterRegister:
            const newState = state[action.payload.key].filter((element, index) => (index !== action.payload.position))
            return {
                ...state,
                [action.payload.key]: [...newState]
            }

        case formTypes.editRepeaterRegister:
            const { key: k, position: p, obj: o } = action.payload
            state[k][p] = {
                ...state[k][p],
                [o.name]: o.value
            }
            return {
                ...state,
                [k]: [...state[k]]
            }

        case formTypes.editPickinRepeaterRegister:
            const { key: kEPicked, position: pEPicked, obj: oEPicked } = action.payload
            state[kEPicked][pEPicked] = {
                ...state[kEPicked][pEPicked],
                [oEPicked.name]: [
                    ...state[kEPicked][pEPicked][oEPicked.name].filter(f => f.variantCountInInstrumental !== oEPicked.value.variantCountInInstrumental),
                    oEPicked.value
                ]
            }
            return {
                ...state,
                [kEPicked]: [...state[kEPicked]]
            }
        case formTypes.removePickinRepeaterRegister:
            const { key: kRPicked, position: pRPicked, obj: oRPicked } = action.payload
            state[kRPicked][pRPicked] = {
                ...state[kRPicked][pRPicked],
                [oRPicked.name]: [ ...state[kRPicked][pRPicked][oRPicked.name].filter(f => f.variantCountInInstrumental !== oRPicked.value) ]
            }
            return {
                ...state,
                [kRPicked]: [...state[kRPicked]]
            }
        case formTypes.cleanForm:
            return initialState

        case formTypes.fillFormData:
            return {
                ...state,
                ...action.payload
            }

        case formTypes.SwitchPermission:
            return {
                ...state,
                [action.payload.key]: {
                    ...state[action.payload.key],
                    [action.payload.permiso]: action.payload.value
                }
            }
        case formTypes.SwitchPermissionAll:
            const newPermision = Object.entries(state[action.payload.key]).map((el) => {
                return { id: [el[0]], value: action.payload.value }
            })

            const newP = newPermision.reduce(
                (obj, item) => Object.assign(obj, { [item.id]: item.value }), {})

            return {
                ...state,
                [action.payload.key]: {
                    ...newP
                }
            }
        case formTypes.FillSelect:
            return {
                ...state,
                [action.payload.id]: {
                    ...state[action.payload.id],
                    select: action.payload.value
                }
            }


        default:
            return state
    }
}

export default formReducer
